/* eslint-disable no-underscore-dangle */
import { parseISO } from 'date-fns';
import _ from 'lodash';
import { COMPANY_PAGE_COPY } from 'pages/Details/Company/constants';
import { STATE_FIELDS } from 'connected/FormFlow/constants';
import { EntityTypes } from 'connected/AuditLogPanel/types';
import { Vertical } from 'globalConstants';
import { LeaseRecord, SaleRecord } from 'pages/Details/types';
import { sortArrayByProp } from 'utils/arr';
import {
  formatArray,
  formatDate,
  formatPrice,
  formatPriceWithPence,
} from './format';
import {
  calculateCapitalValue,
  checkIfCanonicalAddress,
  formatCompanyDebtPayload,
  formatDebtPayload,
  formatForMap,
  formatValuationPayload,
  getBuildingId,
  getCanonicalAddress,
  getDealDetails,
  getDemiseComparison,
  getFloorPartition,
  getGeomDetails,
  getIndicator,
  getLandlordTenant,
  getLandlordTenantComparison,
  getLastPrice,
  getLeaseEvents,
  getLeaseLength,
  getLongLease,
  getLongLeaseComparison,
  getMatchingRecords,
  getNextBreakReviewDate,
  getNextRentReviewDate,
  getNotesArrayComparison,
  getOtherAddresses,
  getOtherNames,
  getPreviousNames,
  getPrice,
  getRoleInfo,
  getSellerBuyer,
  getSellerBuyerComparison,
  getSpaceAvailable,
  mapRelatedBuildings,
  mapRelatedCompanies,
  sortMatchingRecords,
} from './helpers';
import { RelationRole, VerticalKeys } from './types';

const parseLeaseDetails = (data: any) => ({
  id: data.id,
  relatedBuildings: data.buildings.map(mapRelatedBuildings),
  status: data.availabilityStatus,
  type: data.type,
  isConfidential: data.dealIsPrivate,
  landlord: getLandlordTenant(data, RelationRole.Landlord),
  tenant: getLandlordTenant(data, RelationRole.Tenant),
  startDate: data.startDate,
  endDate: data.endDate,
  totalArea: data.totalArea,
  measurementStandard: data.measurementStandard,
  achievedRent: data.achievedRent,
  offeredRent: data.offeredRent,
  askingRent: data.askingRent,
  serviceCharge: data.serviceCharge,
  act54: data.act54,
  repairingObligations: data.term,
  breakOptions: data.breakOptions,
  geom: getGeomDetails(data),
  rentReviews: data.rentReviews,
  rentFreeMonths: data.rentFreeMonthsInitial,
  rentFreeMonthsTotal: data.rentFreeMonthsTotal,
  halfRentFreeMonths: data.halfRentMonthsInitial,
  capitalContribution: data.capitalContribution,
  entityName: data.__entityName__,
  primaryUse: data.primaryUse,
  titleNumber: data.titleNumber,
  terminationDate: data.terminationDate,
  terminationReason: data.terminationReason,
  areaSqFt: data.areaSqFt,
  readyToOccupyDate: data.readyToOccupyDate,
  description: data.description,
  netEffectiveRent: data.netEffectiveRent,
  instructionDate: data.instructionDate,
  jobCode: data.jobCode,
  numberOfOffers: data.numberOfOffers,
  numberOfEnquiries: data.numberOfEnquiries,
  onMarketDate: data.onMarketDate,
  offMarketDate: data.offMarketDate,
  contractSignedDate: data.contractSignedDate,
  headsOfTermsAgreedDate: data.headsOfTermsAgreedDate,
  holdingOver: data.holdingOver,
  totalRent: data.annualRent,
  leaseTerm: getLeaseLength(
    parseISO(data.startDate),
    parseISO(data.endDate),
    true,
  ),
  floorPartition: getFloorPartition(data),
  nextRentReview: data.rentReviews[0]
    ? getNextRentReviewDate(data.rentReviews)
    : '',
  nextBreakDate: data.breakOptions[0]
    ? getNextBreakReviewDate(data.breakOptions)
    : '',
  zoneARent: data.zoneARent,
  linkedToTurnover: data.linkedToTurnover,
  premiumToLandlord: data.premium,
  useClauses: data.useClauses,
  spaceAvailable: getSpaceAvailable(data),
  minimumLettableArea: data.minimumLettableArea,
  maximumLettableArea: data.maximumLettableArea,
  dealDetails: getDealDetails(data),
  demiseParentId: data.demiseParentId,
  relatedParties: [
    ...getRoleInfo(data?.crmLeases, RelationRole.LandlordAgent),
    ...getRoleInfo(data?.crmLeases, RelationRole.LandlordLeaseConsultant),
    ...getRoleInfo(data?.crmLeases, RelationRole.LandlordsSolicitor),
    ...getRoleInfo(data?.crmLeases, RelationRole.Other),
    ...getRoleInfo(data?.crmLeases, RelationRole.TenantAgent),
    ...getRoleInfo(data?.crmLeases, RelationRole.TenantLeaseConsultant),
    ...getRoleInfo(data?.crmLeases, RelationRole.TenantsSolicitor),
  ],
  auditLog: data.auditLogs,
  notes: data.notes,
  isDisputed: data.isDisputed,
  fieldsWithCompetingValues: data.fieldsWithCompetingValues,
  fieldsWithValues: data.fieldsWithValues,
  isConfirmedTotalRent: data.isConfirmedTotalRent,
  isHidden: data.isHidden,
  isDeleted: data.isDeleted || false,
  deletedReason: data.deletedReason,
  grade: data.grade,
  grade2: data.grade2,
  fitOutCategory: data.fitOutCategory,
  breeamRating: data.breeamRating,
  wiredScoreRating: data.wiredScoreRating,
  wellCertification: data.wellCertification,
});

const parseToLetDetails = (data: any) => ({
  ...parseLeaseDetails(data),
  relatedParties: [
    ...getRoleInfo(data?.crmLeases, RelationRole.LandlordAgent),
    ...getRoleInfo(data?.crmLeases, RelationRole.LandlordsSolicitor),
    ...getRoleInfo(data?.crmLeases, RelationRole.Other),
  ],
});

const parseBuildingDetailsLeaseTableDetails = (
  data: any,
  allLeases: any,
  sortedMatchingRecords: any,
) => {
  const matchingRecords = getMatchingRecords(data, allLeases, Vertical.Lease);
  return {
    availabilityStatus: data.availabilityStatus,
    type: data.type,
    isConfidential: data.dealIsPrivate,
    landlords: data.landlords,
    landlordAgents: data.landlordAgents,
    tenant: data.tenant,
    startDate: data.startDate,
    endDate: data.endDate,
    totalArea: data.totalArea,
    achievedRent: data.achievedRent
      ? parseFloat(data.achievedRent.toFixed(2))
      : undefined,
    annualRent: data.annualRent
      ? parseFloat(data.annualRent.toFixed(2))
      : undefined,
    rent:
      data.availabilityStatus === 'let' ? data.achievedRent : data.offeredRent,
    totalRent: data.annualRent,
    floorPartition: getFloorPartition(data),
    nextRentReview:
      data.rentReviews && data.rentReviews[0]
        ? getNextRentReviewDate(data.rentReviews)
        : '',
    nextBreakDate:
      data.breakOptions && data.breakOptions[0]
        ? getNextBreakReviewDate(data.breakOptions)
        : '',
    primaryUse: data.primaryUse,
    minimumLettableArea: data.minimumLettableArea,
    maximumLettableArea: data.maximumLettableArea,
    isDisputed: data.isDisputed,
    hasMatchingRecords: {
      indicator: getIndicator(
        data.leaseId,
        Vertical.Lease,
        sortedMatchingRecords,
      ),
      matches: matchingRecords,
      vertical: Vertical.Lease,
    },
  };
};

const combineTrustScaleData = (data: any, trustScaleData: any) => {
  return Object.keys(data).reduce((accum: {}, record) => {
    if (trustScaleData[record]) {
      const recordSource = trustScaleData[record].sources?.find(
        (source: { entityId: string; source: string }) =>
          source.entityId === data.id,
      );
      const entityName = data.__entityName__;
      let recordSourceValue = recordSource?.source;
      if (
        trustScaleData[record].mostTrusted === data.id &&
        trustScaleData[record].source
      ) {
        recordSourceValue = trustScaleData[record].source;
      }
      return {
        ...accum,
        [record]: {
          value: data[record],
          source: recordSourceValue,
          entity: entityName,
          isMostTrusted: trustScaleData[record].mostTrusted === data.id,
        },
      };
    }
    return { ...accum, [record]: { value: data[record] } };
  }, {});
};

export const parseSaleComparisonDetails = (data: any, trustscaleData?: any) => {
  const combinedData = data.map((record: any) =>
    combineTrustScaleData(record, trustscaleData),
  );

  return combinedData.map((recordItem: any) => ({
    id: recordItem.id,
    buildingId: {
      ...recordItem.buildingId,
      value: getBuildingId(recordItem.buildings.value),
      entity:
        recordItem.buildings?.length > 0
          ? recordItem.buildings[0].__entityName__
          : 'Sale',
    },
    isPortfolio: recordItem.isPortfolio,
    isConfidential: recordItem.dealIsPrivate,
    availabilityStatus: recordItem.availabilityStatus,
    sellers: getSellerBuyerComparison(
      recordItem.crmSales.value,
      RelationRole.Vendor,
      trustscaleData,
      '$Vendor',
    ),
    buyersSPV: getSellerBuyerComparison(
      recordItem.crmSales.value,
      RelationRole.HoldingEntity,
      trustscaleData,
      '$Holding Entity',
    ),
    buyers: getSellerBuyerComparison(
      recordItem.crmSales.value,
      RelationRole.Purchaser,
      trustscaleData,
      '$Purchaser',
    ),
    askingPrice: recordItem.askingPrice,
    achievedPrice: recordItem.achievedPrice,
    offeredPrice: recordItem.offeredPrice,
    listingDate: recordItem.onMarketDate,
    soldDate: recordItem.transactionDate,
    offerDate: recordItem.underOfferDate,
    underOfferDate: recordItem.underOfferDate,
    withdrawnDate: recordItem.offMarketDate,
    tenure: recordItem.tenure,
    annualRentalIncome: recordItem.rentalIncome,
    netInitialYield: recordItem.netInitialYield,
    purchaserCosts: recordItem.purchaserCosts,
    equivalentYield: recordItem.equivalentYield,
    reversionaryYield: recordItem.reversionaryYield,
    wault: recordItem.weightedAverageUnexpiredLeaseTerm,
    waultc: recordItem.weightedAverageUnexpiredLeaseTermCertain,
    rentalTopUpAmount: recordItem.rentalTopUpAmount,
    longLease: getLongLeaseComparison(
      trustscaleData,
      recordItem.longLeases.value,
    ),
    purchaseType: recordItem.ownerOccupierPurchase.value
      ? STATE_FIELDS.OWNER
      : STATE_FIELDS.INVESTOR,
    entityName: recordItem.__entityName__,
    onMarketTransaction: recordItem.onMarketTransaction,
    propertyUrl: recordItem.propertyUrl,
    numberOfEnquiries: recordItem.numberOfEnquiries,
    numberOfOffers: recordItem.numberOfOffers,
    numberOfViewings: recordItem.numberOfViewings,
    jobCode: recordItem.jobCode,
    instructionDate: recordItem.instructionDate,
    practicalCompletionDate: recordItem.practicalCompletion,
    readyToOccupyDate: recordItem.readyToOccupyDate,
    capitalPrice: recordItem.capitalValue,
    totalAreaAtSale: recordItem.totalAreaAtSale,
    isDisputed: recordItem.isDisputed,
    vacantPossession: recordItem.vacantPossession,
    freeholder: recordItem?.crmSales
      ? getSellerBuyerComparison(
          recordItem.crmSales.value,
          RelationRole.Freeholder,
          trustscaleData,
          '$Freeholder',
        )
      : null,
    hasSellerAgent: recordItem.hasSellerAgent,
    hasBuyerAgent: recordItem.hasBuyerAgent,
    buyerSolicitors: recordItem?.crmSales
      ? getSellerBuyerComparison(
          recordItem.crmSales.value,
          RelationRole.BuyersSolicitor,
          trustscaleData,
          "$Buyer's Solicitor",
        )
      : null,
    buyerAgents: recordItem?.crmSales
      ? getSellerBuyerComparison(
          recordItem.crmSales.value,
          RelationRole.AcquiringAgent,
          trustscaleData,
          '$Acquiring Agent',
        )
      : null,
    sellerAgents: recordItem?.crmSales
      ? getSellerBuyerComparison(
          recordItem.crmSales.value,
          RelationRole.DisposingAgent,
          trustscaleData,
          '$Disposing Agent',
        )
      : null,
    sellerSolicitors: recordItem?.crmSales
      ? getSellerBuyerComparison(
          recordItem.crmSales.value,
          RelationRole.SellersSolicitor,
          trustscaleData,
          "$Seller's Solicitor",
        )
      : null,
    other: recordItem?.crmSales
      ? getSellerBuyerComparison(
          recordItem.crmSales.value,
          RelationRole.Other,
          trustscaleData,
          '$Other',
        )
      : null,
    notesAll: {
      ...getNotesArrayComparison(trustscaleData, recordItem.notes.value),
      payloadObj: recordItem.notes,
    },
    grade: recordItem.grade,
    grade2: recordItem.grade2,
    fitOutCategory: recordItem.fitOutCategory,
    breeamRating: recordItem.breeamRating,
    wiredScoreRating: recordItem.wiredScoreRating,
    wellCertification: recordItem.wellCertification,
  }));
};

const getDemisePayloadObject = (recordItem: any) => {
  const demises = {
    value: [
      {
        askingRent: recordItem.askingRent.value
          ? {
              value: recordItem.askingRent.value,
            }
          : null,
        achievedRent: recordItem.achievedRent.value
          ? {
              value: recordItem.achievedRent.value,
            }
          : null,
        netEffectiveRent: recordItem.netEffectiveRent.value
          ? {
              value: recordItem.netEffectiveRent.value,
            }
          : null,
      },
    ],
  };

  if (recordItem.unitsLeases.value.length > 0) {
    const singleUnitLease = recordItem.unitsLeases.value[0];

    Object.assign(demises.value[0], {
      floor: {
        floorId: {
          value: singleUnitLease.floorId,
        },
      },
    });

    Object.assign(demises.value[0], {
      partition: singleUnitLease.partitionId
        ? {
            partitionId: {
              value: singleUnitLease.partitionId,
            },
          }
        : null,
    });
  }

  return demises;
};

export const parseLeaseComparisonDetails = (
  data: any,
  trustscaleData?: any,
) => {
  const combinedData = data.map((record: any) => {
    // eslint-disable-next-line no-param-reassign
    record.rentReviews = record.rentReviews.filter(
      (item: any) => !(item.isVoided === true),
    );
    // eslint-disable-next-line no-param-reassign
    record.breakOptions = record.breakOptions.filter(
      (item: any) => !(item.isVoided === true),
    );
    return combineTrustScaleData(record, trustscaleData);
  });

  return combinedData.map((recordItem: any) => ({
    id: recordItem.id,
    buildingId: {
      ...recordItem.buildingId,
      value: getBuildingId(recordItem.buildings.value),
      entity:
        recordItem.buildings?.length > 0
          ? recordItem.buildings[0].__entityName__
          : 'Lease',
    },
    isConfidential: recordItem.dealIsPrivate,
    isDisputed: recordItem.isDisputed,
    status: recordItem.availabilityStatus,
    type: recordItem.type,
    landlord: getLandlordTenantComparison(
      recordItem.crmLeases.value,
      RelationRole.Landlord,
      trustscaleData,
      '$Landlord',
    ),
    tenant: getLandlordTenantComparison(
      recordItem.crmLeases.value,
      RelationRole.Tenant,
      trustscaleData,
      '$Tenant',
    ),
    startDate: recordItem.startDate,
    endDate: recordItem.endDate,
    totalArea: recordItem.totalArea,
    measurementStandard: recordItem.measurementStandard,
    achievedRent: recordItem.achievedRent,
    offeredRent: recordItem.offeredRent,
    askingRent: recordItem.askingRent,
    serviceCharge: recordItem.serviceCharge,
    act54: recordItem.act54,
    repairingObligations: recordItem.term,
    rentFreeMonths: recordItem.rentFreeMonthsInitial,
    rentFreeMonthsTotal: recordItem.rentFreeMonthsTotal,
    halfRentFreeMonths: recordItem.halfRentMonthsInitial,
    capitalContribution: recordItem.capitalContribution,
    entityName: recordItem.__entityName__,
    primaryUse: recordItem.primaryUse,
    titleNumber: recordItem.titleNumber,
    terminationDate: recordItem.terminationDate,
    terminationReason: recordItem.terminationReason,
    areaSqFt: recordItem.areaSqFt,
    readyToOccupyDate: recordItem.readyToOccupyDate,
    description: recordItem.description,
    netEffectiveRent: recordItem.netEffectiveRent,
    instructionDate: recordItem.instructionDate,
    jobCode: recordItem.jobCode,
    numberOfOffers: recordItem.numberOfOffers,
    numberOfEnquiries: recordItem.numberOfEnquiries,
    onMarketDate: recordItem.onMarketDate,
    offMarketDate: recordItem.offMarketDate,
    contractSignedDate: recordItem.contractSignedDate,
    headsOfTermsAgreedDate: recordItem.headsOfTermsAgreedDate,
    holdingOver: recordItem.holdingOver,
    totalRent: recordItem.annualRent,
    rentReviews: {
      ...recordItem.rentReviews,
      value: getLeaseEvents(recordItem.rentReviews, EntityTypes.RENT_REVIEW),
      payloadObj: recordItem.rentReviews,
    },
    breakOptions: {
      ...recordItem.breakOptions,
      value: getLeaseEvents(recordItem.breakOptions, EntityTypes.BREAK_OPTIONS),
      payloadObj: recordItem.breakOptions,
    },
    zoneARent: recordItem.zoneARent,
    linkedToTurnover: recordItem.linkedToTurnover,
    premiumToLandlord: recordItem.premium,
    useClauses: {
      ...recordItem.useClauses,
      value: formatArray(recordItem.useClauses.value),
      payloadObj: recordItem.useClauses,
    },
    minimumLettableArea: recordItem.minimumLettableArea,
    maximumLettableArea: recordItem.maximumLettableArea,
    isConfirmedTotalRent: recordItem.isConfirmedTotalRent,
    tenantAgent: recordItem?.crmLeases
      ? getSellerBuyerComparison(
          recordItem.crmLeases.value,
          RelationRole.TenantAgent,
          trustscaleData,
          '$Tenant Agent',
        )
      : null,
    tenantLeaseConsultant: recordItem?.crmLeases
      ? getSellerBuyerComparison(
          recordItem.crmLeases.value,
          RelationRole.TenantLeaseConsultant,
          trustscaleData,
          "$Tenant's Lease Consultant",
        )
      : null,
    tenantSolicitor: recordItem?.crmLeases
      ? getSellerBuyerComparison(
          recordItem.crmLeases.value,
          RelationRole.TenantsSolicitor,
          trustscaleData,
          "$Tenant's Solicitor",
        )
      : null,
    landlordAgent: recordItem?.crmLeases
      ? getSellerBuyerComparison(
          recordItem.crmLeases.value,
          RelationRole.LandlordAgent,
          trustscaleData,
          '$Landlord Agent',
        )
      : null,
    landlordLeaseConsultant: recordItem?.crmLeases
      ? getSellerBuyerComparison(
          recordItem.crmLeases.value,
          RelationRole.LandlordLeaseConsultant,
          trustscaleData,
          "$Landlord's Lease Consultant",
        )
      : null,
    landlordSolicitor: recordItem?.crmLeases
      ? getSellerBuyerComparison(
          recordItem.crmLeases.value,
          RelationRole.LandlordsSolicitor,
          trustscaleData,
          "$Landlord's Solicitor",
        )
      : null,
    other: recordItem?.crmLeases
      ? getSellerBuyerComparison(
          recordItem.crmLeases.value,
          RelationRole.Other,
          trustscaleData,
          '$Other',
        )
      : null,
    notesAll: {
      ...getNotesArrayComparison(trustscaleData, recordItem.notes.value),
      payloadObj: recordItem.notes,
    },
    demise: {
      ...getDemiseComparison(trustscaleData, recordItem.unitsLeases.value),
      payloadObj: getDemisePayloadObject(recordItem),
    },
    grade: recordItem.grade,
    grade2: recordItem.grade2,
    fitOutCategory: recordItem.fitOutCategory,
    breeamRating: recordItem.breeamRating,
    wiredScoreRating: recordItem.wiredScoreRating,
    wellCertification: recordItem.wellCertification,
  }));
};

export const parseSaleDetails = (data: any) => ({
  id: data.id,
  relatedBuildings: data.buildings?.map((b: any) =>
    mapRelatedBuildings(b, data),
  ),
  buildingId: getBuildingId(data.buildings),
  isPortfolio: data.isPortfolio,
  isConfidential: data.dealIsPrivate,
  status: data.availabilityStatus,
  sellers: getSellerBuyer(data.crmSales, RelationRole.Vendor),
  buyersSPV: getSellerBuyer(data.crmSales, RelationRole.HoldingEntity),
  buyers: getSellerBuyer(data.crmSales, RelationRole.Purchaser),
  achievedPrice: data.achievedPrice,
  askingPrice: data.askingPrice,
  offeredPrice: data.offeredPrice,
  listingDate: data.onMarketDate,
  soldDate: data.transactionDate,
  offerDate: data.underOfferDate,
  underOfferDate: data.underOfferDate,
  withdrawnDate: data.offMarketDate,
  tenure: data.tenure,
  annualRentalIncome: data.rentalIncome,
  netInitialYield: data.netInitialYield,
  purchaserCosts: data.purchaserCosts,
  equivalentYield: data.equivalentYield,
  reversionaryYield: data.reversionaryYield,
  wault: data.weightedAverageUnexpiredLeaseTerm,
  waultc: data.weightedAverageUnexpiredLeaseTermCertain,
  rentalTopUpAmount: data.rentalTopUpAmount,
  longLease: getLongLease(data.longLeases),
  otherDetails:
    data.notes && data.notes.length > 0 ? data.notes[0].description : null,
  purchaseType: data.ownerOccupierPurchase
    ? STATE_FIELDS.OWNER
    : STATE_FIELDS.INVESTOR,
  entityName: data.__entityName__,
  onMarketTransaction: data.onMarketTransaction,
  propertyUrl: data.propertyUrl,
  numberOfEnquiries: data.numberOfEnquiries,
  numberOfOffers: data.numberOfOffers,
  numberOfViewings: data.numberOfViewings,
  jobCode: data.jobCode,
  instructionDate: data.instructionDate,
  practicalCompletionDate: data.practicalCompletion,
  readyToOccupyDate: data.readyToOccupyDate,
  geom: getGeomDetails(data),
  capitalPrice: data.capitalValue,
  totalAreaAtSale: data.totalAreaAtSale,
  relatedParties: [
    ...getRoleInfo(data?.crmSales, RelationRole.AcquiringAgent, true),
    ...getRoleInfo(data?.crmSales, RelationRole.BuyersSolicitor, true),
    ...getRoleInfo(data?.crmSales, RelationRole.Other, true),
    ...getRoleInfo(data?.crmSales, RelationRole.DisposingAgent, true),
    ...getRoleInfo(data?.crmSales, RelationRole.SellersSolicitor, true),
  ],
  auditLog: data.auditLogs,
  notes: data.notes,
  portfolioName: data.portfolioName || '',
  numberOfBuildings: data.numberOfBuildings,
  isDisputed: data.isDisputed,
  vacantPossession: data.vacantPossession,
  freeholder: data?.crmSales
    ? getSellerBuyer(data.crmSales, RelationRole.Freeholder)
    : null,
  hasSellerAgent: data.hasSellerAgent,
  hasBuyerAgent: data.hasBuyerAgent,
  fieldsWithCompetingValues: data.fieldsWithCompetingValues,
  fieldsWithValues: data.fieldsWithValues,
  isHidden: data.isHidden,
  isDeleted: data.isDeleted || false,
  investmentCategory: data.investmentCategory,
  grade: data.grade,
  grade2: data.grade2,
  fitOutCategory: data.fitOutCategory,
  breeamRating: data.breeamRating,
  wiredScoreRating: data.wiredScoreRating,
  wellCertification: data.wellCertification,
});

const parseSaleTableDetails = (data: any, allSales: any) => {
  const sortedMatchingRecords = sortMatchingRecords(allSales, Vertical.Sale);
  const matchingRecords = getMatchingRecords(data, allSales, Vertical.Sale);
  return {
    isConfidential: data.dealIsPrivate,
    status: data.availabilityStatus,
    sellers: data.sellers,
    buyers: data.buyers,
    price: getPrice(data),
    date: data.sortingDate,
    netInitialYield: data.netInitialYield,
    purchaserCosts: data.purchaserCosts,
    equivalentYield: data.equivalentYield,
    reversionaryYield: data.reversionaryYield,
    waultc: data.waultc,
    totalRent: data.rentalIncome,
    capitalValue: data.isPortfolio ? null : calculateCapitalValue(data),
    relatedParties: [
      ...getRoleInfo(data?.crmSales, RelationRole.AcquiringAgent),
      ...getRoleInfo(data?.crmSales, RelationRole.DisposingAgent),
    ],
    isDisputed: data.isDisputed,
    hasMatchingRecords: {
      indicator: getIndicator(
        data.saleId,
        Vertical.Sale,
        sortedMatchingRecords,
      ),
      matches: matchingRecords,
      vertical: Vertical.Sale,
    },
  };
};

const parseCompanyDetails = (data: any) => ({
  summary: mapRelatedCompanies(data),
  companyName: data.name,
  companyRegistrationNumber: data.registrationNumber,
  duns: data.dunsNumber,
  sector: data.primaryBusinessType,
  nationality: data.countryOfOrigin,
  legalName: data.legalName,
  companyClosed: data.closed,
  owner: data.ultimateOwner,
  website: data.website,
  savCrmId: data.savCrmId,
  savCrmNearestId: data.savCrmNearestId,
  entityName: data.__entityName__,
  propertyOwnership: [
    {
      label: COMPANY_PAGE_COPY.propertyOwnershipPanel.ownership,
      type: 'ownership',
      count: data.ownershipCount,
      total: data.ownershipTotalArea,
      buildings: formatForMap(data.ownershipBuildings),
    },
    {
      label: COMPANY_PAGE_COPY.propertyOwnershipPanel.occupation,
      type: 'occupation',
      count: data.occupationCount,
      total: data.occupationTotalArea,
      buildings: formatForMap(data.occupationBuildings),
    },
    {
      label: COMPANY_PAGE_COPY.propertyOwnershipPanel.ownerOccupation,
      type: 'owner-occupation',
      count: data.ownerOccupationCount,
      total: data.ownerOccupationTotalArea,
      buildings: formatForMap(data.ownerOccupationBuildings),
    },
  ],
  relatedParties: [
    ...getRoleInfo(data?.crmCompanies, RelationRole.OccupierServicersProvider),
    ...getRoleInfo(data?.crmCompanies, RelationRole.Other),
    ...getRoleInfo(data?.crmCompanies, RelationRole.PropertyValuer),
  ],
  auditLog: data.auditLogs,
  notes: data.notes,
  structure: [data.structure],

  relatedLandlordOrTenantInLeaseRecords:
    data.relatedLandlordOrTenantInLeaseRecords,
  relatedLandlordInToLetRecords: data.relatedLandlordInToLetRecords,
  relatedBuyerSellerInSalePortfolioSaleRecords:
    data.relatedBuyerSellerInSalePortfolioSaleRecords,
  relatedRelatedPartiesRecords: data.relatedRelatedPartiesRecords,

  isPrivate: data.isPrivate,
  fieldsWithCompetingValues: data.fieldsWithCompetingValues,
  fieldsWithValues: data.fieldsWithValues,
  previousCompanyNames: getPreviousNames(data.previousNames),
  debts: data.crmDebts ? formatCompanyDebtPayload(data.crmDebts) : undefined,
  canWatch: data.canWatch,
  watchId: data.watchId,
  tradingAsNames: data.tradingAsNames,
  introhiveCompanyId: data.introhiveCompanyId,
  introhiveName: data.introhiveName,
  introhiveCompanyCapitalScore: data.introhiveCompanyCapitalScore,
  introhiveRelationshipStrengthScore: data.introhiveRelationshipStrengthScore,
  equivalentBondRating: data.equivalentBondRating,
  incansGlobalScore: data.incansGlobalScore,
  parents: data.parents,
});

export const getOrderedLeasesByLevelDescending = (leases: any) =>
  _.orderBy(
    leases,
    [(c) => (c && c.floors && c.floors[0] && c.floors[0].floorLevel) || 0],
    ['desc'],
  );

const parseBuildingDetails = (data: any) => {
  const sortedMatchingRecords = sortMatchingRecords(
    data.leases,
    Vertical.Lease,
  );

  const formattedLeases = data.leases.map((lease: LeaseRecord) => ({
    ...lease,
    ...parseBuildingDetailsLeaseTableDetails(
      lease,
      data.leases,
      sortedMatchingRecords,
    ),
  }));

  const formattedSales = data.sales.map((sale: SaleRecord) => ({
    ...sale,
    ...parseSaleTableDetails(sale, data.sales),
  }));

  const orderedSales = sortArrayByProp(formattedSales, 'sortingDate', true);
  const latestSale: any = orderedSales.find(
    (sale: any) => sale.availabilityStatus !== 'withdrawn',
  );
  const latestValuations: any = sortArrayByProp(
    data.valuations,
    'valuationDate',
    true,
  );

  let latestValuation = '';

  if (latestValuations && latestValuations.length > 0) {
    latestValuation = `${formatPrice(
      latestValuations[0].valuationAmount,
    )} (${formatDate(latestValuations[0].valuationDate)})`;
  }

  return {
    summary: mapRelatedBuildings(data),
    sales: formattedSales,
    leases: getOrderedLeasesByLevelDescending(formattedLeases),
    floors: data.floors,
    status: latestSale ? latestSale.availabilityStatus : null,
    geom: {
      latLng: {
        lat: data.geom?.coordinates[1],
        lng: data.geom?.coordinates[0],
      },
      name: data.buildingName,
    },
    primaryUse: data.primaryUse,
    otherUses: data.otherUses,
    ownerOccupied: data.occupiedByOwner,
    totalArea: data.totalArea,
    totalAreaAtSale: latestSale ? latestSale.totalAreaAtSale : null,
    capitalValue:
      data.mostRecentSoldSale && !data.mostRecentSoldSale.isPortfolio
        ? data.mostRecentSoldSale.capitalValue
        : null,
    spv: data.holdingCompany,
    landlords: data.landlords,
    isCanonicalAddress: checkIfCanonicalAddress(data.otherAddresses),
    canonicalBuildingId: getCanonicalAddress(data.otherAddresses),
    avgRent:
      data.averageRent > 0
        ? `${formatPriceWithPence(data.averageRent)} (estimated)`
        : null,
    knownTenants: data.knownTenants,
    lastPrice: data.mostRecentSoldSale
      ? getLastPrice(data.mostRecentSoldSale)
      : null,
    netInitialYield: data.mostRecentSoldSale
      ? data.mostRecentSoldSale.netInitialYield
      : null,
    wault: data.wault,
    waultc: data.waultc,
    market: data.market,
    entityName: data.__entityName__,
    epc: data.epcRating,
    gradeType: data.gradeType,
    gradeDescription: data.gradeDescription,
    constructionStarted: data.constructionStarted,
    constructionCompleted: data.constructionCompleted,
    amenities: data.amenities,
    carParking: data.carParking,
    lorryParking: data.lorryParking,
    eavesHeight: data.eavesHeight,
    dockDoors: data.numDockDoors,
    levelDoors: data.numLevelDoors,
    yardDepth: data.yardDepth,
    crossDocks: data.crossDocks,
    secureYard: data.secureYard,
    uprn: data.uprns,
    localAuthority: data.localAuthority,
    region: data.region,
    website: data.website,
    alias: data.alias.split(','),
    otherAddresses: getOtherAddresses(data),
    otherNames: getOtherNames(data),
    mostRecentSoldSale: data.mostRecentSoldSale,
    relatedParties: [
      ...getRoleInfo(data?.crmBuildings, RelationRole.AssetManager),
      ...getRoleInfo(data?.crmBuildings, RelationRole.DebtProvider),
      ...getRoleInfo(data?.crmBuildings, RelationRole.FundManager),
      ...getRoleInfo(data?.crmBuildings, RelationRole.Other),
      ...getRoleInfo(data?.crmBuildings, RelationRole.PropertyManager),
      ...getRoleInfo(data?.crmBuildings, RelationRole.DilapidationsManager),
      ...getRoleInfo(
        data?.crmBuildings,
        RelationRole.BuildingAndPropertyConsultant,
      ),
      ...getRoleInfo(data?.crmBuildings, RelationRole.PropertyValuer),
      ...getRoleInfo(data?.crmBuildings, RelationRole.Landlord),
    ],
    auditLog: data.auditLogs,
    notes: data.notes,
    earliestLeaseStart: data.earliestLeaseStart,
    latestLeaseEnd: data.latestLeaseEnd,
    headerAddress: data.headerAddress,
    instructions: sortArrayByProp(data.instructions, 'startDate', true),
    buildingDemolished: data.buildingDemolished,
    fieldsWithCompetingValues: data.fieldsWithCompetingValues,
    fieldsWithValues: data.fieldsWithValues,
    debts: data.debts ? formatDebtPayload(data.debts) : undefined,
    canWatch: data.canWatch,
    watchId: data.watchId,
    valuations: data.valuations
      ? formatValuationPayload(data.valuations)
      : undefined,
    latestValuation,
    primaryUseSubCategories: data.primaryUseSubCategories,
    investmentSubCategories: data.investmentSubCategories,
    isDeleted: data.isDeleted || false,
    deletedReason: data.deletedReason,
    grade: data.grade,
    grade2: data.grade2,
    fitOutCategory: data.fitOutCategory,
    breeamRating: data.breeamRating,
    wiredScoreRating: data.wiredScoreRating,
    wellCertification: data.wellCertification,
    isProtected: data.isProtected,
    subMarkets: data.subMarkets,
    listingType: data.listingType,
    epcRatingNumber: data.epcRatingNumber,
    epcCombined:
      data.epcRating && data.epcRatingNumber
        ? `${data.epcRating} - ${data.epcRatingNumber?.toFixed(1)}`
        : null,
    epcCertificates: data.epcCertificates,
  };
};

const verticalParsers = {
  [Vertical.Building]: parseBuildingDetails,
  [Vertical.ToLet]: parseToLetDetails,
  [Vertical.Lease]: parseLeaseDetails,
  [Vertical.Sale]: parseSaleDetails,
  [Vertical.PortfolioSale]: parseSaleDetails,
  [Vertical.Company]: parseCompanyDetails,
};

const formatter = (vertical: VerticalKeys, data: any, trustscaleData?: any) => {
  const parserFn = verticalParsers[vertical];

  if (trustscaleData) {
    return {
      vertical,
      [vertical]:
        vertical === Vertical.Sale
          ? parseSaleComparisonDetails(data, trustscaleData)
          : parseLeaseComparisonDetails(data, trustscaleData),
      documents: data.documents,
    };
  }

  return {
    vertical,
    [vertical]: parserFn(data),
    documents: data.documents,
  };
};

export default formatter;
