import React from 'react';
import Tooltip from 'components/Tooltip/Tooltip';
import { globalContent, urls } from 'globalConstants';
import { convertNumberThousandSeparator } from 'utils/string';
import { INTROHIVE_PILL_COPY } from './constants';
import {
  CompanyIntrohiveAccentColour1,
  CompanyIntrohiveLogo,
  CompanyIntrohivePillOutline,
  CompanyIntrohivePillLink,
  CompanyIntrohivePillValue,
  CompanyIntrohiveAccentColour2,
  CompanyIntrohiveNearestLogo,
} from './IntrohivePillStyled';
import { IntrohivePillProps, IntrohiveRSSPillProps } from './types';

const CompanyIntrohiveItem = ({
  value,
  valueNearest,
  colour,
}: {
  value?: number;
  valueNearest?: number;
  colour: string;
}) => {
  return (
    <>
      {valueNearest ? (
        <CompanyIntrohivePillOutline colour={colour}>
          <CompanyIntrohiveNearestLogo />
          <CompanyIntrohivePillValue>
            {convertNumberThousandSeparator(valueNearest as number)}
          </CompanyIntrohivePillValue>
        </CompanyIntrohivePillOutline>
      ) : (
        <CompanyIntrohivePillOutline colour={colour}>
          <CompanyIntrohiveLogo />
          <CompanyIntrohivePillValue>
            {convertNumberThousandSeparator(value as number)}
          </CompanyIntrohivePillValue>
        </CompanyIntrohivePillOutline>
      )}
    </>
  );
};

export const IntrohiveRSSPill: React.FC<IntrohiveRSSPillProps> = ({
  introhiveName,
  value,
  valueNearest,
  companyId,
  disableTooltip,
}) => {
  const valueNearestToShow = value ? undefined : valueNearest;

  return (
    <IntrohivePill
      introhiveName={introhiveName}
      value={value}
      valueNearest={valueNearestToShow}
      colour={CompanyIntrohiveAccentColour1}
      companyId={companyId}
      tooltip={
        valueNearestToShow
          ? INTROHIVE_PILL_COPY.nearestRelationshipStrengthScore
          : INTROHIVE_PILL_COPY.relationshipStrengthScore
      }
      disableTooltip={disableTooltip}
    />
  );
};

export const IntrohiveCCSPill: React.FC<IntrohiveRSSPillProps> = ({
  introhiveName,
  value,
  companyId,
  disableTooltip,
}) => (
  <IntrohivePill
    introhiveName={introhiveName}
    value={value}
    colour={CompanyIntrohiveAccentColour2}
    companyId={companyId}
    tooltip={INTROHIVE_PILL_COPY.companyCapitalScore}
    disableTooltip={disableTooltip}
  />
);

const IntrohivePill: React.FC<IntrohivePillProps> = ({
  introhiveName,
  value,
  valueNearest,
  colour,
  companyId,
  tooltip,
  disableTooltip,
}) => {
  const introhiveUrl = `${urls.introhive}${encodeURIComponent(
    introhiveName as string,
  )}`;

  const tooltipId = `${companyId}_${tooltip.replace(' ', '_')}`;

  const introhiveLinkContent = introhiveName ? (
    <CompanyIntrohivePillLink
      href={introhiveUrl}
      rel="noopener noreferrer"
      target="_blank"
      title={globalContent.introhive}
    >
      <CompanyIntrohiveItem
        value={value}
        valueNearest={valueNearest}
        colour={colour}
      />
    </CompanyIntrohivePillLink>
  ) : (
    <CompanyIntrohiveItem
      value={value}
      valueNearest={valueNearest}
      colour={colour}
    />
  );

  return (
    <>
      {disableTooltip ? (
        introhiveLinkContent
      ) : (
        <Tooltip tooltipId={tooltipId} button={introhiveLinkContent}>
          {tooltip}
        </Tooltip>
      )}
    </>
  );
};

export default IntrohivePill;
