import { FORM_FLOWS } from 'connected/FormFlow/constants';
import {
  FIELD_COMPONENTS,
  FormFlowTypes,
  OptionProps,
  ValidationProps,
} from 'connected/FormFlow/types';
import { Conditional } from 'services/conditions';

export const GOOGLE_MAP_API_KEY =
  process.env.REACT_APP_GOOGLE_API || 'AIzaSyDfG9MnvIFsDyki4pRcg2DipKpWWKtLfKo';

export const GEOAPIFY_MAP_API_KEY =
  process.env.REACT_APP_GEOAPIFY_API || `c6a63d3325ee48a6b078cd9ff8f42c50`;

export enum ENVIRONMENT {
  DEV = 'Dev',
  QA = 'Qa',
  STAGING = 'Stg',
  PRODUCTION = 'Prod',
}

export const GTM_ID = 'GTM-W2BMF8F';

export const KEYBOARD_EVENT_VALUES = {
  TAB: 9,
  ENTER: 13,
  ESCAPE: 27,
  SPACE: 32,
  LEFT: 37,
  UP: 38,
  RIGHT: 39,
  DOWN: 40,
};

export const DOCUMENT_TYPES = [
  {
    value: 'associated-lease-doc',
    label: 'Associated Lease Doc',
  },
  {
    value: 'company-annual-account',
    label: 'Company Annual Account',
  },
  {
    value: 'development-appraisal',
    label: 'Development Appraisal',
  },
  {
    value: 'floor-plan-measurement-survey',
    label: 'Floor Plan & Measurement Survey',
  },
  {
    value: 'heads-of-terms',
    label: 'Heads of Terms',
  },
  {
    value: 'image',
    label: 'Image',
  },
  {
    value: 'investment-brochure',
    label: 'Investment Brochure',
  },
  {
    value: 'investment-summary',
    label: 'Investment Summary',
  },
  {
    value: 'lease',
    label: 'Lease',
  },
  {
    value: 'letting-particulars',
    label: 'Letting Particulars',
  },
  {
    value: 'planning-development-document',
    label: 'Planning & Development Document',
  },
  {
    value: 'portfolio-sale-brochure',
    label: 'Portfolio Sale Brochure',
  },
  {
    value: 'press-release',
    label: 'Press Release',
  },
  {
    value: 'service-charge-budget',
    label: 'Service Charge Budget',
  },
  {
    value: 'tenancy-schedule',
    label: 'Tenancy Schedule',
  },
  {
    value: 'title-doc',
    label: 'Title Doc',
  },
  {
    value: 'valuation-comps-evidence',
    label: 'Valuation & Comps Evidence',
  },
  {
    value: 'epc-report',
    label: 'EPC Report',
  },
  {
    value: 'other',
    label: 'Other',
  },
];

export const ACCEPTED_FILE_TYPES = [
  'doc',
  'docx',
  'dwg',
  'pdf',
  'xls',
  'xlsx',
  'png',
  'jpg',
  'jpeg',
  'avux',
  'ppt',
  'pptx',
];

export const ACCEPTED_FILE_MIME_TYPES = [
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/pdf',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/octet-stream',
  'application/vnd.ms-powerpoint',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'image/x-dwg',
  'image/png',
  'image/jpeg',
];

export enum INPUT_FORMAT_TYPE {
  CURRENCY = 'currency',
  PERCENTAGE = 'percentage',
  NUMERIC = 'numeric',
  INTEGER = 'integer',
  YEAR = 'year',
  SQUARE_FT = 'square_ft',
  METERS = 'meters',
  PRICE_PER_SQ_FT = 'price_per_sq_ft',
  MONTH = 'month',
}

export const globalContent = {
  thousandSeparator: ',',
  decimalSeparator: '.',
  currency: '£',
  year: 'year',
  years: 'years',
  squareFt: 'sq ft',
  perSqFt: 'per sq ft',
  month: 'month',
  months: 'months',
  meters: 'm',
  edit: 'Edit',
  nationality: 'GB',
  addData: 'Add data',
  save: 'Save',
  saving: 'Saving...',
  cancel: 'Cancel',
  selectSource: 'Select Source',
  updateSource: 'Update Source',
  updating: 'updating...',
  merging: 'merging...',
  adding: 'updating...',
  updated: 'updated!',
  loading: 'Loading...',
  yes: 'Yes',
  no: 'No',
  approx: 'Approx',
  days: 'days',
  emptyCell: '-',
  actions: 'Actions',
  close: 'close',
  done: 'Done',
  view: 'View all',
  all: 'all',
  logout: 'Log out',
  to: 'to',
  from: 'from',
  profile: 'Profile',
  training: 'Training',
  mainAddress: 'main address',
  endOfMonth: 'End of Month',
  companiesHouse: 'view on Companies House',
  introhive: 'view on Introhive',
  unknown: 'unknown',
  move: 'Move',
  renew: 'Renew',
  split: 'Split',
  delete: 'Delete',
  hide: 'Hide',
  unhide: 'Unhide',
  hidden: 'currently hidden',
  historic: 'historic',
  merge: 'Merge Selected',
  rentReview: 'Rent Review',
  breakOptions: 'Break Option',
  landRegistry: 'HM Land Registry',
} as const;

export enum Vertical {
  Building = 'building',
  Company = 'company',
  Sale = 'sale',
  Lease = 'lease',
  ToLet = 'toLet',
  PortfolioSale = 'portfolioSale',
}

export enum VerticalSubElement {
  Debt = 'debt',
  Valuation = 'valuation',
}

export const VerticalLabelMap = {
  building: 'Building',
  company: 'Company',
  sale: 'Sale',
  lease: 'Lease',
  toLet: 'To Let',
  portfolioSale: 'Portfolio Sale',
};

export type CoreEntity =
  | Vertical.Building
  | Vertical.Company
  | Vertical.Lease
  | Vertical.Sale;

export const MILES_IN_METERS = 1609;

export const urls = {
  searchDetails: (vertical: Vertical, id: string, anchor?: string) =>
    `/search/details/${vertical}/${id}${anchor || ''}`,
  search: '/search',
  searchPrimaryFilter: '/search/primary/',
  searchMap: '/search/map',
  searchFilters: '/search/filters',
  searchResults: '/search/results',
  login: '/login',
  newSale: {
    single: '/new-sale/single-sale',
    portfolio: '/new-sale/portfolio-sale',
  },
  newLease: {
    single: '/new-lease/single-lease',
  },
  mapPlaceholder: '/map',
  admin: '/admin',
  crm: process.env.REACT_APP_CRM_LINK,
  profile: '/profile',
  training:
    'https://mylearning.savills.com/lms/index.php?r=course/deeplink&course_id=805&hash=6030ea62054d18323c86d45f2a89406799e3fb35&generated_by=19104',
  recordNotFound: (id: string, vertical: Vertical) =>
    `/deleted/${vertical}/${id}`,
  companiesHouse:
    'https://find-and-update.company-information.service.gov.uk/company/',
  introhive: 'https://uk.introhive.com/company/?company=',

  analytics: {
    root: {
      page: '/analytics',
      iframe: '',
    },
    teamLeaderboard: {
      page: '/team-leaderboard',
      iframe:
        'https://app.powerbi.com/reportEmbed?reportId=fce3c019-fe5d-4ebf-a0e4-2e407007b750&autoAuth=true&ctid=97852f08-acc2-46c3-a799-2a2015d8da45',
    },
    athenaContribution: {
      page: '/athena-contribution',
      iframe:
        'https://app.powerbi.com/reportEmbed?reportId=88e49f4d-178a-46db-ab04-1ac9478652bf&autoAuth=true&ctid=97852f08-acc2-46c3-a799-2a2015d8da45',
    },
    lendingAnalytics: {
      page: '/lending-analytics',
      iframe:
        'https://app.powerbi.com/reportEmbed?reportId=fd07df72-708c-41b8-8679-2224ceac536d&autoAuth=true&ctid=97852f08-acc2-46c3-a799-2a2015d8da45&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLW5vcnRoLWV1cm9wZS1qLXByaW1hcnktcmVkaXJlY3QuYW5hbHlzaXMud2luZG93cy5uZXQvIn0%3D',
    },
    epcAnalytics: {
      page: '/epc-analytics',
      iframe:
        'https://app.powerbi.com/reportEmbed?reportId=6e5efe3d-ea4a-4d74-ac70-7df04dd0a31b&autoAuth=true&ctid=97852f08-acc2-46c3-a799-2a2015d8da45',
    },
    marketAnalytics: {
      page: '/market-analytics',
      iframe:
        'https://app.powerbi.com/reportEmbed?reportId=4ae2673f-0e2d-477b-83ef-ac6671893531&autoAuth=true&ctid=97852f08-acc2-46c3-a799-2a2015d8da45',
    },
    tenantDashboard: {
      page: '/tenant-dashboard',
      iframe:
        'https://app.powerbi.com/reportEmbed?reportId=dd625d2e-72db-4b7d-ad39-3fb30f7d3ca0&autoAuth=true&ctid=97852f08-acc2-46c3-a799-2a2015d8da45',
    },
  },
};

export const getAbsoluteUrl = (path: string) => {
  return `${window.location.origin}${path}`;
};

const generateEndpoint = (path: string) =>
  `${process.env.REACT_APP_API_DOMAIN}/${path}`;

const getVerticalEndpoint = (vertical: Vertical) => {
  if (vertical === Vertical.ToLet) {
    return Vertical.Lease;
  }

  if (vertical === Vertical.PortfolioSale) {
    return Vertical.Sale;
  }

  return vertical;
};

const shouldExcludeCurrentCompany = (excludeCurrentCompany?: string) => {
  if (excludeCurrentCompany) {
    return `&excludeIds[]=${excludeCurrentCompany}`;
  }

  return '';
};

export const QUICKSEARCH_RESULT_LIMIT = 25;

export const endpoints = {
  telemetry: generateEndpoint('telemetry'),
  telemetryStatistics: generateEndpoint('telemetry/statistics'),
  auth: {
    login: generateEndpoint('auth/login'),
    logout: generateEndpoint('auth/logout'),
  },
  state: generateEndpoint('state'),
  sale: generateEndpoint('sale'),
  lease: generateEndpoint('lease'),
  vertical: {
    byId: (vertical: Vertical, id: string) => {
      const verticalEndpoint = getVerticalEndpoint(vertical);
      return generateEndpoint(`${verticalEndpoint}/${id}`);
    },
    search: (vertical: Vertical, query: string, limit: number, page: number) =>
      generateEndpoint(
        `${vertical}/search?query=${query}&limit=${limit}&page=${page}`,
      ),
    mergeRecords: (vertical: Vertical) => generateEndpoint(`${vertical}/merge`),
    compareRecords: (vertical: Vertical, ids: string[]) => {
      const verticalEndpoint = getVerticalEndpoint(vertical);
      const listOfIds = ids.reduce((accum: string, id: string) => {
        return `${accum}&ids=${id}`;
      }, '');
      return generateEndpoint(`${verticalEndpoint}/multi/?${listOfIds}`);
    },
  },
  document: {
    upload: generateEndpoint('document/upload'),
    onUploadSuccess: generateEndpoint('document/upload/success'),
    download: (entityId: string, filename: string) =>
      generateEndpoint(
        `document/signed-url?entityId=${entityId}&filename=${filename}`,
      ),
    delete: generateEndpoint('document/delete'),
  },
  editDetails: generateEndpoint('entity/field'),
  editLeaseRecord: generateEndpoint('lease'),
  editBuildingRecord: generateEndpoint('building'),
  editSaleRecord: generateEndpoint('sale'),
  editCompanyRecord: generateEndpoint('company'),
  deleteCompany: generateEndpoint('company'),
  editCompanyParents: generateEndpoint('company/parents'),
  combinedCompanySearch: (
    query: string,
    limit: number,
    page: number,
    includeIsPrivate = false,
    excludeCurrentCompany?: string,
  ) => {
    const endpoint = `company/combined-search?query=${query}&limit=${limit}&page=${page}&includeIsPrivate=${includeIsPrivate}${shouldExcludeCurrentCompany(
      excludeCurrentCompany,
    )}`;
    return generateEndpoint(endpoint);
  },
  searchQuery: generateEndpoint('search'),
  searchStatistics: generateEndpoint('search-statistics'),
  deleteEntity: generateEndpoint('entity'),
  deleteBuilding: generateEndpoint('entity'),
  portfolioSaleMatchingRecords: generateEndpoint(
    'sale/portfolios/last-12-months',
  ),
  portfolioNames: generateEndpoint('sale/portfolios/names'),
  updateHistory: (id: string, vertical: Vertical, entity: string) =>
    generateEndpoint(`entity/${vertical}/${id}/update-history/${entity}`),
  userQuery: generateEndpoint('user-query'),
  listUsers: generateEndpoint('admin/list-users'),
  freezeUser: generateEndpoint('admin/freeze-user'),
  unfreezeUser: generateEndpoint('admin/unfreeze-user'),
  userProfile: generateEndpoint('user-profile'),
  userProfileScheduledQuery: generateEndpoint('user-profile/scheduled-query'),
  moveEntity: (vertical: Vertical, subElement?: VerticalSubElement) =>
    generateEndpoint(`${vertical}/${subElement ?? 'building'}`),
  renewEntity: (vertical: Vertical) => generateEndpoint(`${vertical}/renew`),
  splitEntity: generateEndpoint(`lease/split`),
  hideEntity: (vertical: Vertical) => generateEndpoint(`${vertical}/hide`),
  deleteRecordEntity: (vertical: Vertical) =>
    generateEndpoint(`${vertical}/delete`),
  addBreakRentEvent: (eventType: string) =>
    generateEndpoint(`lease/${eventType}`),
  deleteScheduleQuery: generateEndpoint('user-profile/scheduled-query'),
  deleteUserQuery: generateEndpoint('user-query'),
  watchRecord: generateEndpoint('entity-watch'),
  buildingProtected: generateEndpoint('building/protected'),
  athenaUser: generateEndpoint('user-profile/athena-user'),
  athenaUserPostVersion: generateEndpoint(
    'user-profile/viewed-release-notes-version',
  ),
  getCompanyDeletionData: (id: string) =>
    generateEndpoint(`company/${id}/deletion-data`),
  scheduleTeamQuery: generateEndpoint('admin/schedule-team-query'),
};

export interface CommonDetailRecordProps {
  key: string;
  keyLabel: string;
  entityName?: string;
  entityId?: string;
  apiKey?: string;
  value?: any;
  isEditable?: boolean;
  isHidden?: boolean;
  isPrepopulated?: boolean;
  componentType?: FIELD_COMPONENTS;
  valueLabels?: Map<string | boolean, string>;
  validation?: ValidationProps;
  isWide?: boolean;
  isExtraWide?: boolean;
  idPath?: Map<string | boolean, string>;
  showLink?: boolean;
  companyId?: string;
  fieldType?: FormFlowTypes;
  editMetaWarning?: string;
  editMetaInfo?: string;
  editMetaConditional?: Conditional[];
  editMetaOptions?: OptionProps[];
  editMetaKey?: string;
  editMetaComponentType?: FIELD_COMPONENTS;
  showExistingValueInline?: boolean;
  icon?: React.ReactNode;
  link?: string;
  linkType?: string;
  isClearable?: boolean;
  decimals?: number;
  verticalType?: Vertical;
  conditionalVerticalType?: {
    conditional: {
      key: string;
      value: any;
      result: Vertical;
    };
    default: Vertical;
  };
  subKey?: string;
  inspectValues?: boolean;
  formatter?: (value: any) => void;
  editModal?: { ref: string; name: FORM_FLOWS; recordId?: string };
  isPrivate?: boolean;
  savCrmId?: string;
  savCrmNearestId?: string;
  chId?: string;
  hasCompetingValues?: boolean;
  fieldsWithCompetingValues?: boolean;
  fieldsWithValues?: boolean;
  isConfirmedTotalRent?: boolean;
  isAdminOnly?: boolean;
  introhiveName?: string;
  introhiveRelationshipStrengthScore?: number;
  introhiveNearestRelationshipStrengthScore?: number;
  expandableArrayThreshold?: number;
  tooltip?: string;
  optionsFilter?: (options: OptionProps[], context: any) => OptionProps[];
  context?: any;
  contextPath?: string[];
}
export interface AuditMapProps {
  keyLabel: string;
  apiKey: string;
  formatter?: (value: any) => void;
}

export const DEAL_DETAILS_TITLE =
  'This lease is part of a deal that totals <span>%%STRING_TO_REPLACE%% sq. ft</span> (%%STRING_TO_REPLACE%% leases).';

export const DEAL_DETAILS_SUBTITLE_TITLE =
  'The original deal was for <span>%%STRING_TO_REPLACE%% sq. ft</span>';

export const OTHER_DEAL_DETAILS_TITLE =
  "<p><span>%%STRING_TO_REPLACE%%</span> has <span>%%STRING_TO_REPLACE%%</span> other lease(s) in this building starting <span>%%STRING_TO_REPLACE%%</span> and ending <span>%%STRING_TO_REPLACE%%</span> that aren't currently part of this deal.</p>";

export const globalVariables = {
  SELECT_MAX_WIDTH: 400,
  DATE_PLACEHOLDER: 'DD/MM/YYYY',
  DEFAULT_ZOOM: 8,
  MAX_ZOOM: 22,
  DEFAULT_PAGE_SIZE: '10',
  DEFAULT_PAGE: '0',
  HOURS_IN_DAY: 24,
};

export const DEFAULT_UK_COUNTRIES = [
  'GB',
  'scotland',
  'england-wales',
  'united-kingdom',
];
