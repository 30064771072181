import React from 'react';
import { useSelector } from 'react-redux';
import equals from 'ramda/src/equals';
import { RootState } from 'store/rootReducer';
import { urls } from 'globalConstants';

import logo from 'assets/athena-heading-logo.svg';
import ReleaseNotes from 'connected/ReleaseNotes/ReleaseNotes';
import {
  GlobalHeaderWrapper,
  MenuWrapper,
  LogoImg,
  HomeLink,
} from './GlobalHeaderStyled';
import GlobalMenu from './GlobalMenu/GlobalMenu';
import AccountMenu from './AccountMenu/AccountMenu';

const GlobalHeader = () => {
  const { isLoggedIn, user } = useSelector(
    (state: RootState) => state.authentication,
    equals,
  );

  return (
    <GlobalHeaderWrapper>
      {isLoggedIn ? (
        <MenuWrapper>
          <HomeLink to={urls.search}>
            <LogoImg src={logo} alt="Savills Athena" />
          </HomeLink>
          <GlobalMenu showAdmin={user?.isAdmin} />
          <AccountMenu name={user?.name.display} />
          <ReleaseNotes />
        </MenuWrapper>
      ) : (
        <MenuWrapper>
          <LogoImg src={logo} alt="Savills Athena" />
        </MenuWrapper>
      )}
    </GlobalHeaderWrapper>
  );
};

export default GlobalHeader;
